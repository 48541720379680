<template>
  <top></top>
  <banner v-model:list="list"></banner>

  <div class="giftscon">
    <div id="cardinfo">
      <span>d03daaacff00f8df</span><br />
      长按复制&nbsp;<a href="javascript:close()">[关闭]</a>
    </div>

    <div class="indexbox pr giftsearch">
      游戏礼包领取
      <input
        placeholder="搜索礼包"
        name="keyword"
        id="keyword"
        value=""
        class="pa"
      />
      <button class="pa"></button>
    </div>

    <ul id="gifts_con" class="giftslist">
      <li class="clearfix">
        <img src="http://h.lequ.com/Public/Uploads/201711/5a041eabcfb82.png" />
        <div>
          <span>决战沙城新手礼包</span>
          <div class="lbcon">羽毛*20 强化石*100 金币*50W</div>
          <div class="jd">剩余:<span>2771</span> | 已领取3228</div>
        </div>
        <a class="getnow pa db" onclick="getcard(385)">领号</a>
      </li>
      <li class="clearfix">
        <img src="http://h.lequ.com/Public/Uploads/201711/5a041eabcfb82.png" />
        <div>
          <span>决战沙城新手礼包</span>
          <div class="lbcon">羽毛*20 强化石*100 金币*50W</div>
          <div class="jd">剩余:<span>2771</span> | 已领取3228</div>
        </div>
        <a class="getnow pa db" onclick="getcard(385)">领号</a>
      </li>
      <div class="loading">加载中...</div>
    </ul>

    <div id="giftDes" class="giftDes" style="">
      <div class="giftDes_box">
        <div class="giftDes_box2">
          <div class="giftDes_1">决战沙城新手礼包</div>
          <div class="giftDes_2">羽毛*20 强化石*100 金币*50W</div>
          <div class="giftDes_3">剩余:<span>2768</span> | 已领取3231</div>
          <div class="giftDes_4">
            <span class="giftDescolse but2">[关闭]</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <fnav></fnav>
</template>

<script>
import top from "../../components/top.vue";
import banner from "../../components/banner.vue";
import fnav from "../../components/fnav.vue";


export default {
  name: "GiftsIndex",
  components: {
    top,
    banner,
    fnav,
  },
  setup() {


    return {
      showuserimg: true,
      showlogo: false,
    };
  },
};
</script>

<style>
</style>